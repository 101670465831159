import { Fragment, useContext } from 'react';

import {
    faBolt,
    faSignOutAlt,
    faChartPie,
    faUserCog,
    faGasPump,
    faHome,
    faMap,
    faWrench,
    faCircleNodes,
    faGears,
    faSitemap,
    faUser,
    faUserGroup,
    faFolder,
    faKey,
    faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Nav, Navbar, NavDropdown, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';

import NavigationFilter from './NavigationFilter';
import AppContext from '../../../context/app-context';
import UserContext from '../../../context/user-context';
import ViewContext from '../../../context/view-context';
import { ObjectReferencesCategories } from '../../../enum/ObjectReferencesCategories';
import { PrivilegesCategories } from '../../../enum/PrivilegesCategories';
import CloseButton from '../../Buttons/General/CloseButton';
import UpgradeNavLink from '../../Buttons/Upgrade/UpgradeNavLink';

const LOGOUT_SERVICE_URL = process.env.REACT_APP_LOGOUT_SERVICE_URL;
const LOGON_SERVICE_URL = process.env.REACT_APP_LOGON_SERVICE_URL;

const Navigation = () => {
    const { t } = useTranslation('', { keyPrefix: 'navigation' });
    const userCtx = useContext(UserContext);
    const { username, isAdmin } = userCtx;
    const appCtx = useContext(AppContext);
    const { isLoading, isChild } = appCtx;
    const viewCtx = useContext(ViewContext);
    const { hasDomainPrivilege, hasGroupPrivilege, hasViewObjectReference } =
        viewCtx;
    const navigate = useNavigate();

    const logOutHandler = () => {
        window.location.href = decodeURI(
            LOGOUT_SERVICE_URL + '?redir=' + LOGON_SERVICE_URL
        );
    };

    return (
        <Navbar
            collapseOnSelect={true}
            expand="lg"
            bg="dark"
            variant="dark"
            className="ps-3 pe-3 shadow-sm-bottom border-bottom">
            <Navbar.Brand>
                <span className="fw-bolder">PWM Cloud Service</span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                {(!isChild && (
                    <Fragment>
                        <Nav className="me-auto">
                            <NavLink
                                name="home"
                                to="/home"
                                className="nav-link">
                                <FontAwesomeIcon
                                    icon={faHome}
                                    className="me-1"
                                />
                                {t('home')}
                            </NavLink>
                            <NavLink
                                name="dashboards"
                                to="/dashboards"
                                className="nav-link"
                                hidden={
                                    !hasGroupPrivilege([
                                        PrivilegesCategories.Group_Dashboard,
                                        PrivilegesCategories.Domain_Dashboard,
                                    ])
                                }
                                disabled={
                                    !hasGroupPrivilege([
                                        PrivilegesCategories.Group_Dashboard,
                                        PrivilegesCategories.Domain_Dashboard,
                                    ])
                                }>
                                <FontAwesomeIcon
                                    icon={faChartPie}
                                    className="me-1"
                                />
                                {t('dashboards')}
                            </NavLink>
                            <UpgradeNavLink
                                hidden={
                                    !(
                                        !hasGroupPrivilege([
                                            PrivilegesCategories.Group_Dashboard,
                                            PrivilegesCategories.Domain_Dashboard,
                                        ]) &&
                                        hasViewObjectReference(
                                            ObjectReferencesCategories.SP_FREE
                                        )
                                    )
                                }
                                disabled={
                                    !(
                                        !hasGroupPrivilege([
                                            PrivilegesCategories.Group_Dashboard,
                                            PrivilegesCategories.Domain_Dashboard,
                                        ]) &&
                                        hasViewObjectReference(
                                            ObjectReferencesCategories.SP_FREE
                                        )
                                    )
                                }
                                text={t('dashboards')}
                            />
                            <NavLink
                                name="stations"
                                to="/stations"
                                className="nav-link">
                                <FontAwesomeIcon
                                    icon={faGasPump}
                                    className="me-1"
                                />
                                {t('stations')}
                            </NavLink>
                            <NavLink name="map" to="/map" className="nav-link">
                                <FontAwesomeIcon
                                    icon={faMap}
                                    className="me-1"
                                />
                                {t('map')}
                            </NavLink>
                            <NavDropdown
                                hidden={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                        PrivilegesCategories.Customer_User_Admin,
                                        PrivilegesCategories.Customer_Group_Admin,
                                        PrivilegesCategories.Customer_Token_Admin,
                                    ])
                                }
                                disabled={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                        PrivilegesCategories.Customer_User_Admin,
                                        PrivilegesCategories.Customer_Group_Admin,
                                        PrivilegesCategories.Customer_Token_Admin,
                                    ])
                                }
                                title={
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faSitemap}
                                            className="me-1"
                                        />
                                        {t('manage.title')}
                                    </span>
                                }
                                active={window.location.pathname.includes(
                                    'manage'
                                )}
                                className="manage">
                                <NavDropdown.Item
                                    as={NavLink}
                                    name="manage-overview"
                                    to="/manage/overview">
                                    <FontAwesomeIcon
                                        icon={faSitemap}
                                        className="me-2"
                                    />
                                    {t('manage.overview')}
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    as={NavLink}
                                    name="manage-users"
                                    to="/manage/users"
                                    hidden={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                            PrivilegesCategories.Customer_User_Admin,
                                        ])
                                    }
                                    disabled={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                            PrivilegesCategories.Customer_User_Admin,
                                        ])
                                    }>
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="me-2"
                                    />
                                    {t('manage.users')}
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={NavLink}
                                    name="manage-groups"
                                    to="/manage/groups"
                                    hidden={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                            PrivilegesCategories.Customer_Group_Admin,
                                        ])
                                    }
                                    disabled={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                            PrivilegesCategories.Customer_Group_Admin,
                                        ])
                                    }>
                                    <FontAwesomeIcon
                                        icon={faUserGroup}
                                        className="me-2"
                                    />
                                    {t('manage.groups')}
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={NavLink}
                                    name="manage-domains"
                                    to="/manage/domains"
                                    hidden={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                        ])
                                    }
                                    disabled={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                        ])
                                    }>
                                    <FontAwesomeIcon
                                        icon={faFolder}
                                        className="me-2"
                                    />
                                    {t('manage.domains')}
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    as={NavLink}
                                    name="manage-tokens"
                                    to="/manage/tokens"
                                    hidden={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                            PrivilegesCategories.Customer_Token_Admin,
                                        ])
                                    }
                                    disabled={
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.PWM_Admin,
                                            PrivilegesCategories.PWM_Service,
                                            PrivilegesCategories.PWM_ReadOnly,
                                            PrivilegesCategories.PWM_Development,
                                            PrivilegesCategories.Customer_Token_Admin,
                                        ])
                                    }>
                                    <FontAwesomeIcon
                                        icon={faKey}
                                        className="me-2"
                                    />
                                    {t('manage.tokens')}
                                </NavDropdown.Item>
                            </NavDropdown>
                            <NavLink
                                name="jobs"
                                to="/jobs"
                                className="nav-link"
                                hidden={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.Jobs,
                                    ])
                                }
                                disabled={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.Jobs,
                                    ])
                                }>
                                <FontAwesomeIcon
                                    icon={faGears}
                                    className="me-1"
                                />
                                {t('jobs')}
                            </NavLink>
                            <UpgradeNavLink
                                hidden={
                                    !(
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.Jobs,
                                        ]) &&
                                        hasViewObjectReference([
                                            ObjectReferencesCategories.SP_FREE,
                                            ObjectReferencesCategories.SP_STANDARD,
                                        ])
                                    )
                                }
                                disabled={
                                    !(
                                        !hasDomainPrivilege([
                                            PrivilegesCategories.Jobs,
                                        ]) &&
                                        hasViewObjectReference([
                                            ObjectReferencesCategories.SP_FREE,
                                            ObjectReferencesCategories.SP_STANDARD,
                                        ])
                                    )
                                }
                                text={t('jobs')}
                            />
                            <NavLink
                                name="external-services"
                                to="/external-services"
                                className="nav-link"
                                hidden={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                    ])
                                }
                                disabled={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                    ])
                                }>
                                <FontAwesomeIcon
                                    icon={faCircleNodes}
                                    className="me-1"
                                />
                                {t('external-services')}
                            </NavLink>
                            <NavLink
                                name="errors"
                                to="/errors"
                                className="nav-link"
                                hidden={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                    ])
                                }
                                disabled={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                    ])
                                }>
                                <FontAwesomeIcon
                                    icon={faCircleExclamation}
                                    className="me-1"
                                />
                                {t('errors')}
                            </NavLink>
                            <NavLink
                                name="administration"
                                to="/administration"
                                className="nav-link"
                                hidden={!isAdmin}
                                disabled={!isAdmin}>
                                <FontAwesomeIcon
                                    icon={faWrench}
                                    className="me-1"
                                />
                                {t('admin')}
                            </NavLink>
                            <NavLink
                                name="ev"
                                to="/ev"
                                className="nav-link"
                                hidden={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                    ])
                                }
                                disabled={
                                    !hasDomainPrivilege([
                                        PrivilegesCategories.PWM_Admin,
                                        PrivilegesCategories.PWM_Service,
                                        PrivilegesCategories.PWM_ReadOnly,
                                        PrivilegesCategories.PWM_Development,
                                    ])
                                }>
                                <FontAwesomeIcon
                                    icon={faBolt}
                                    className="me-1"
                                />
                                {t('ev')}
                            </NavLink>
                        </Nav>
                        <Nav>
                            {isLoading && (
                                <span className="me-3">
                                    <Spinner
                                        animation="grow"
                                        variant="success"
                                    />
                                </span>
                            )}
                            <NavigationFilter />
                            <Button
                                name="settings"
                                variant="outline-light"
                                className="ms-2 text-nowrap"
                                onClick={() => {
                                    navigate('/user/settings');
                                }}>
                                <FontAwesomeIcon
                                    icon={faUserCog}
                                    className="me-2"
                                />
                                {username ?? ''}
                            </Button>
                            <Button
                                name="logout"
                                variant="outline-light"
                                className="ms-2"
                                onClick={logOutHandler}>
                                <FontAwesomeIcon icon={faSignOutAlt} />
                            </Button>
                        </Nav>
                    </Fragment>
                )) || (
                    <Fragment>
                        <Nav className="me-auto"></Nav>
                        <Nav>
                            <CloseButton
                                hidden={window.opener === null}
                                outline={true}
                                closeHandler={() => window.close()}
                            />
                        </Nav>
                    </Fragment>
                )}
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Navigation;
